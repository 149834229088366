<template>
  <section class="l-content">
    <el-tabs type="border-card" value="site" v-if="settingInfo">
      <el-tab-pane name="site" label="网站设置">
        <site-setting :setting="settingInfo"></site-setting>
      </el-tab-pane>
      <el-tab-pane name="other" label="其他设置">
        <other-setting :setting="settingInfo"></other-setting>
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import siteSetting from './setting/site_setting.vue'
import otherSetting from './setting/other_setting.vue'
export default {
  components: { siteSetting, otherSetting },
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'EnterpriseSetting',
  data() {
    return {
      settingInfo: null
    }
  },
  created() {
    this.getSetting()
  },
  methods: {
    getSetting() {
      this.$lgh.get('api/enterprise/setting/get', 'enterpriseCode').then(res => {
        this.settingInfo = res
      })
    }
  }
}
</script>
