<template>
  <el-upload
    class="avatar-uploader"
    :class="mini ? 'avatar-uploader-mini' : ''"
    :action="actionUrl"
    :show-file-list="false"
    :on-success="handleSuccess"
    :on-error="handleError"
    :before-upload="beforeUpload"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
import { dealResponse } from '../../../utils/ajax'
import { Loading } from 'element-ui'
var loadingInstance = null
export default {
  name: 'LUploadImg',
  model: {
    prop: 'modelVal',
    event: 'input'
  },
  props: {
    mini: {
      type: Boolean,
      default: true
    },
    modelVal: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      actionUrl: process.env.VUE_APP_API_URL + 'api/file/upload',
      imageUrl: ''
    }
  },
  mounted() {
    this.imageUrl = this.modelVal
  },
  methods: {
    handleSuccess(res, file) {
      loadingInstance && loadingInstance.close()
      dealResponse(res, data => {
        this.imageUrl = data.fullUrl
        this.$emit('input', data.fullUrl)
      })
    },
    handleError(res, file) {
      loadingInstance && loadingInstance.close()
      this.$message.error(res)
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG,PNG 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
        return false
      }
      loadingInstance = Loading.service({
        fullscreen: true,
        text: '上传中',
        background: 'rgba(0,0,0,.1)'
      })
      return true
    }
  },
  watch: {
    modelVal(value) {
      this.imageUrl = value
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader-mini .avatar-uploader-icon {
  font-size: 20px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.avatar-uploader-mini .avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>
